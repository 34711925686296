<template>
  <div id="new-ngb-team-members-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ NextYearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5 pb-0">
        <h4>{{ PageDescription }} {{ NextYearName }}</h4>
      </v-card-subtitle>
      <v-container class="py-0" v-if="floatButtonFlag">
        <v-speed-dial
          v-if="floatButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
          <v-btn
            fab
            dark
            small
            color="indigo"
            @click.prevent="addRecordPrompt = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </v-container>
      <v-dialog v-model="addRecordPrompt" persistent scrollable max-width="75%">
        <new-ngb-team-members-modify
          pageDescription="Assign National Governing board members for the year"
          :addRecordPrompt="addRecordPrompt"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
          boardType=2
        ></new-ngb-team-members-modify>
      </v-dialog>

      <v-dialog v-model="editRecordPrompt" persistent scrollable>
        <new-ngb-team-members-edit
          pageDescription="Edit National Governing board members for the year"
          :editRecordPrompt="editRecordPrompt"
          :recordData="selectedData"
          @hideEditRecordPrompt="hideEditRecordPrompt"
          v-if="editRecordPrompt"
        ></new-ngb-team-members-edit>
      </v-dialog>

      <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
        <member-document-list
          v-if="memberDocumentPrompt"
          :record="selectedData"
          :pageDescription="memberDocumentTitle"
          @hideDialog="hideMemberDocumentPrompt($event)"
        ></member-document-list>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-row wrap>
          <v-col cols="12" md="12" align="center" class="pt-0">
            <v-btn
              v-if="refreshButtonFlag"
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="font-size-h6 white--text"
              @click.prevent="refreshPageData"
            >
              Refresh
            </v-btn>
            <v-btn
              elevation="30"
              shaped
              tile
              small
              color="#66cd00"
              class="font-size-h6 my-3 mr-3 white--text"
              @click.prevent="addRecordPrompt = true"
            >
              Assign Member
            </v-btn>
          </v-col>
        </v-row>

        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <h3 class="text-primary">{{ rows.length }} members found.</h3> -->

              <v-data-iterator
                v-if="rows.length > 0"
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="12"
                      sm="6"
                      md="2"
                      lg="2"
                    >
                      <v-card raised hover elevation="25">
                        <v-card-title>
                          {{ item.MonthTxt }}
                        </v-card-title>
                        <v-card-text>
                          <v-img
                            width="200"
                            :src="item.ProfilePic"
                            :alt="item.MemberFullname"
                          />
                          <br/>
                          <h5>{{ item.MemberFullname }}</h5>
                          <h5 class="text-primary">
                            {{ item.DesignationName }}
                          </h5>
                          <h6 style="color: green">{{ item.MembershipId }}</h6>
                          <h6 style="color: brown">{{ item.ZoneName }}</h6>
                          <h6 style="color: red">{{ item.LomName }}</h6>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip
                            bottom
                            v-if="item.DesignationId != '' && editFlag"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="25px"
                                color="#ff2500"
                                @click="editData(item, e)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-pencil-box-outline</v-icon
                              >
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" cols="12" md="12">
              <p class="notes-title">Important:</p>
              <ol class="notes-content">
                <li>
                  As per Article 4.2 of policy manual part 2, all elective post
                  members should be active members.
                </li>
                <li>
                  All the elective officers should upload their age proof
                  document under their members profile update page.
                </li>
                <!-- <li>
                  <a target="_blank" href="https://youtu.be/U1It3oltTvM"
                    >Click here to watch the tutorial <v-icon color="#c4302b"> mdi-youtube </v-icon> </a
                  >
                </li> -->
                <li>
                  To assign NGB members for the next year click on &nbsp;
                  <span style="background-color: #66cd00; color: white">
                    Assign members
                  </span>
                  &nbsp; button.
                </li>
              </ol>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import NewNgbTeamMembersModify from "@/view/pages/erp/team-members/new-ngb/NewNgbTeamMembersModify.vue";
import NewNgbTeamMembersEdit from "@/view/pages/erp/team-members/new-ngb/NewNgbTeamMembersEdit.vue";
import MemberDocumentList from "@/view/pages/members/MemberDocumentList.vue";

export default {
  mixins: [common],
  components: {
    NewNgbTeamMembersModify,
    NewNgbTeamMembersEdit,
    MemberDocumentList,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      memberDocumentPrompt: false,
      memberDocumentTitle: "",

      refreshButtonFlag: false,
      floatButtonFlag: false,

      pageTitle: "National Directory Information",
      pageDescription:
        "List of National Governing board members for the year 2022",

      rows: [],
      tableSelectFlag1: false,
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      editFlag: true,
      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      FromZoneCodeRules: [(v) => !!v || "Zone is required"],
      FromZoneCode: "",
      FromZoneCodeOptions: [],
      FromZoneCodeOptionsLoading: false,

      FromLomCodeRules: [(v) => !!v || "LOM is required"],
      FromLomCode: "",
      FromLomCodeOptions: [],
      FromLomCodeOptionsLoading: false,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
  },
  methods: {
    memberDocumentData(tr) {
      console.log("memberDocumentData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.memberDocumentTitle = "Documents for " + tr.MemberFullname;
        this.memberDocumentPrompt = true;
      } else {
        var message = "Kindly select one member.";
        this.toast("error", message);
      }
    },
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        // this.member.document_flag = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "ngb_dis",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.getTableRecords();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.getTableRecords();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log("LomCode=" + LomCode);

      if (LomCode != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/lists";
        var upload = {
          UserInterface: 3,
          OrganizationTypeId: 1,
          YearCode: this.CurrentYearId,
          ExceptDesignationId: [1, 5, 7],
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.rows = records.TableData;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableSelectFlag1 = records.TableSelectFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#new-ngb-team-members-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>